import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import Home from '../pages/Home';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import { FacebookProvider } from 'react-facebook';
import AgeGate from '../pages/AgeGate';

const AGE_GATE = 'SD-ag';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2EBB1A'
    }
  }
});

function App() {
  const [cookies, setCookie, removeCookie] = useCookies([AGE_GATE]);

  return (
    <ThemeProvider theme={theme}>
      {!cookies[AGE_GATE] ? 
        <AgeGate /> :
        <Router>
          <Switch>
            <Route path="/">
              <FacebookProvider appId="3139975702920574">
                <Home />
              </FacebookProvider>
            </Route>
          </Switch>
        </Router>
      }
    </ThemeProvider>
  );
}

export default App;
