// Add imports for functions from SDKs for Firebase products you're using
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuiOLWsYF2NyoLt_HG29GQaqvTkz60OLo",
  authDomain: "sticky-dojo-v1.firebaseapp.com",
  projectId: "sticky-dojo-v1",
  storageBucket: "sticky-dojo-v1.appspot.com",
  messagingSenderId: "128597314336",
  appId: "1:128597314336:web:24a30319fef29d11922545",
  measurementId: "G-TVBXTGW8WD"
};

// (window.self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcsYjkgAAAAAKcHlwXc_QDjHKRyQZKZMg5o86Mk'),
  isTokenAutoRefreshEnabled: true
});
