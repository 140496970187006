import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';

import { useCookies } from 'react-cookie';

import bgImg from '../assets/age-gate-background.jpg';
import logo from '../assets/logo-2.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundBlendMode: 'darken',
    backgroundColor: 'brightgreen'
  },
  formCard: {
    background: 'rgba(0,0,0,0.75)',
    borderRadius: 0,
    border: '2px solid black',
    color: 'white',
    width: '420px',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: '100vh',
      justifyContent: 'center',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginTop: 24,
    width: 320,
    padding: 8,
  },
  title: {
    fontFamily: "'Reggae One', cursive"
  },
  formCardHeader: {
    paddingBottom: 0
  },
  formCardContent: {
    fontFamily: 'Work Sans',
    fontWeight: 300,
    textAlign: 'center',
    fontSize: '1.5rem',
    paddingTop: 0,
  },
  formCardButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: '3rem',
  },
  formCardButton: {
    maxWidth: '180px',
    fontFamily: 'Work Sans',
    fontWeight: 300,
    '&:hover ': {
      backgroundColor: '#60BA46',
      color: '#404040',
    }
  },
  ageGateFailedIcon: {
    fontSize: '20rem',
    fill: 'rgba(255,255,255,0.5)'
  }
}));

const AGE_GATE_COOKIE_NAME = 'SD-ag';
const AGE_GATE_MAX_AGE = 60 * 60 * 24 * 14;  // 14 days in seconds

function AgeGate() {
  const classes = useStyles();
  const [cookies, setCookie, removeCookie] = useCookies([AGE_GATE_COOKIE_NAME]);
  const [failed, setFailed] = useState(false);

  const clickedOver21 = () => {
    setCookie(AGE_GATE_COOKIE_NAME, 'true', { maxAge: AGE_GATE_MAX_AGE});
  };
  
  const clickedUnder21 = () => {
    setFailed(true);
  };

  return (
    <div className={classes.root}>
      {failed ?
        <BlockIcon className={classes.ageGateFailedIcon} /> :
        <Card className={classes.formCard}>
          <CardHeader className={classes.formCardHeader} title={<img src={logo} alt="Sitcky Dojo Logo" className={classes.logo} />} />
          <CardContent className={classes.formCardContent}>
            <p>You must be 21 or older to visit this site.</p>
            <p>Please verify your age.</p>
            <div className={classes.formCardButtons}>
              <Button onClick={clickedOver21} className={classes.formCardButton} variant="outlined" color="inherit">I am 21 or older</Button>
              <Button onClick={clickedUnder21} className={classes.formCardButton} variant="outlined" color="inherit">I am under 21</Button>
            </div>
          </CardContent>
        </Card>
      }
    </div>
  );
}

export default AgeGate;
