import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, makeStyles, useScrollTrigger } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import logo from '../assets/logo-5.png';

export interface HeaderProps {
  hideNavBar?: boolean;
  onNavBarOpen?: () => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: 'white',
    color: '#404040',
    fontFamily: 'inherit',
    '&.hide': {
      display: 'none'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    height: 44,
    marginTop: 4,
  },
  logoWrapper: {
    flexGrow: 1
  },
  loginButton: {
    fontFamily: "'Reggae One', cursive",
    borderColor: "#404040"
  },
  offset: theme.mixins.toolbar,
}));

function Header(props: HeaderProps) {
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  
  return (
    <div className="Header">
      <AppBar position="fixed" className={`${classes.appBar} ${trigger ? 'show' : 'hide'}`}>
        <Toolbar>
          {!props.hideNavBar && 
          <IconButton className={classes.menuButton} onClick={props.onNavBarOpen} edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          }
          <div className={classes.logoWrapper}>
            <img src={logo} alt="Sitcky Dojo Logo" className={classes.logo} />
          </div>
          {/* <Button variant="outlined" color="inherit" className={classes.loginButton}>Login</Button> */}
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </div>
  );
}

export default Header;