import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails, 
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  TextField,
  Checkbox
} from '@material-ui/core';

import SmsIcon from '@material-ui/icons/Sms';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

import { firestore } from '../firebase';
import { addDoc, collection } from 'firebase/firestore';

import { Like } from 'react-facebook';

import Header from '../components/Header';
import boxImg from '../assets/sticky-dojo-box.png';
import howItWorksImg1 from '../assets/how-it-works-1.svg';
import howItWorksImg2 from '../assets/how-it-works-2.svg';
import howItWorksImg3 from '../assets/how-it-works-3.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkmark from 'react-typescript-checkmark';

import * as EmailValidator from 'email-validator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },

    /************************* TITLE *************************/
    titleSection: {
      background: 'linear-gradient(0deg, rgba(64,64,64,1) 0%, rgba(49,200,26,1) 100%)',
      color: '#F8F9FA',
    },
    titleGrid: {
      maxWidth: 1280,
      textAlign: 'center',
      margin: 'auto',
      padding: '10px',
    },
    title: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 900,
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 300,
        marginBottom: 24,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 500,
        fontSize: '3.75rem',
      }, 
    },
    titleImg: {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '280px'
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '500px'
      }, 
    },
    wave: {
      width: '100vw',
      margin: 0,
      [theme.breakpoints.up('md')]: {
        marginTop: -72,
      }, 
    },

    /************************* HOW IT WORKS *************************/
    howItWorksSection: {
      textAlign: 'center',
      backgroundColor: 'white',
      marginTop: '-1rem',
      paddingBottom: 50,
      [theme.breakpoints.up('lg')]: {
        marginTop: '-4rem',
      },
    },
    howItWorksGrid: {
      padding: '16px',
      [theme.breakpoints.up('lg')]: {
        padding: '0 4rem'
      }
    },
    howItWorksTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 900,
    },
    howItWorksStepImg: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '280px'
      }, 
      [theme.breakpoints.down('sm')]: {
        maxWidth: '240px',
        marginTop: '2rem',
      },
    },
    howItWorksStepTitle: {
      fontFamily: 'Work Sans',
      fontWeight: 300,
      marginTop: '-2.5rem',
      color: '#404040',
    },
    howItWorksStepDescription: {
      marginTop: '1rem',
      fontFamily: 'Work Sans',
      fontWeight: 300,
      padding: '0 2rem'
    },
    howItWorksQuote: {
      marginTop: '1rem',
      fontFamily: "'Poppins', sans-serif",
      fontSize: '1.1rem',
      fontWeight: 'bold',
      color: 'grey',
    },
    
    /************************* FAQ *************************/
    faqSection: {
      color: 'white',
      background: 'linear-gradient(0deg, rgba(49,200,26,1) 0%, rgba(64,64,64,1) 100%)',
    },
    faqTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 900,
      textAlign: 'center',
      marginBottom: 20,
      marginTop: 24,
      [theme.breakpoints.up('md')]: {
        marginTop: -80,
      }, 
    },
    faqAccordionWrapper: {
      margin: 'auto',
      marginBottom: 96,
      maxWidth: 320,
      [theme.breakpoints.up('md')]: {
        maxWidth: 640
      },
    },
    faqQuestionExpandIcon: {
      color: '#60BA46',
    },
    faqQuestion: {
      color: '#60BA46',
      fontWeight: 'bold',
    },
    faqAnswer: {
      color: '#404040',
    },

    /************************* SIGNUP *************************/
    signupSection: {
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        marginTop: '-8px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '-92px',
      },
    },
    signupButton: {
      margin: '2rem 0',
      color: 'white',
    },
    signupForm: {
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    signupText: {
      fontFamily: 'Work Sans',
      fontWeight: 300,
      textAlign: 'center',
      color: '#404040',
      maxWidth: 600,
      margin: '0 24px',
      [theme.breakpoints.down('md')]: {
        marginBottom: 24,
        marginTop: 48,
      },
    },
    signupEmail: {
      width: 320,
    },
    signupZip: {
      [theme.breakpoints.down('md')]: {
        marginTop: 16,
        width: 320,
      },
      [theme.breakpoints.up('md')]: {
        margin: '0 16px',
        width: 100,
      },
    },
    signupConsent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'white',
    },
    signupConsentCheckboxRequired: {
      color: '#F44336',
    },
    signupConsentText: {
      fontFamily: 'Work Sans',
      textAlign: 'justify',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 280
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 360
      },
    },
    signupComplete: {
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        marginTop: '-8px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '-112px',
      },
    },
    signupCompleteText: {
      fontFamily: 'Work Sans',
      fontWeight: 300,
      color: '#404040',
      marginTop: 24,
      marginBottom: 20,
    },
    signupShareText: {
      fontFamily: 'Work Sans',
      fontWeight: 300,
      color: '#404040',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 320
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 800
      },
    },
    shareButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
      marginBottom: 40,
    },
    shareFbButtonWrapper: {
    },
    shareTwitterWrapper: {
    },
    shareSmsButtonWrapper: {
      marginLeft: 4,
      [theme.breakpoints.down('sm')]: {
        display: 'initial',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      '& a': {
        textDecoration: 'none'
      },
    },
    shareSmsButton: {
      color: 'white',
      backgroundColor: 'rgba(64, 64, 64, 0.65)',
      padding: '2px 12px',
      '& .MuiButton-label': {
        fontWeight: 'bold',
        textTransform: 'none',
        '& .MuiButton-startIcon': {
          marginBottom: -2,
        }
      }
    },

    /************************* FOOTER *************************/
    footer: {
      backgroundColor: 'white',
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '0.75rem',
      fontFamily: 'Work Sans',
      fontWeight: 300,
      paddingTop: 16,
    },
    footerLinks: {
      display: 'flex',
    },
    footerLink: {
      color: 'inherit',
    },
    footerIcon: {
      color: 'inherit',
      fontSize: '1rem',
    },
    footerCopyrightDojo: {
      color: '#60BA46'
    }
  }),
);

function Home() {
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [zip, setZip] = useState('');
  const [zipValid, setZipValid] = useState(false);
  const [consent, setConsent] = useState(false);
  const [signedUp, setSignedUp] = useState(false);

  const classes = useStyles();

  const titleRef = useRef(document.createElement("div"));
  const howItWorksRef = useRef(document.createElement("div"));
  const faqRef = useRef(document.createElement("div"));
  const signupEmailRef = useRef(document.createElement("input"));

  const scrollToSignup = () => {
    signupEmailRef.current.focus({preventScroll: true});
    signupEmailRef.current.scrollIntoView({behavior: "smooth"});
  };

  const onSignupClick = () => {
    setSubmitted(true);
    if (emailValid && zipValid && consent) {
      const signupCollection = collection(firestore, "earlyAccessSignups");
      addDoc(signupCollection, {email, zip});
      setSignedUp(true);
    }
  };

  const handleEmailInput = (e: any) => {
    setEmail(e.target.value);
    const isValid = EmailValidator.validate(e.target.value);
    setEmailValid(isValid);
  };

  const handleZipInput = (e: any) => {
    setZip(e.target.value);
    const zipNumVal = Number(e.target.value) || 0;
    const isValid = e.target.value.length === 5 && zipNumVal >= 501 && zipNumVal <= 99950;
    setZipValid(isValid);
  };

  const handleConsentCheckbox = (e: any) => {
    setConsent(e.target.checked);
  };

  const signupButtonDisabled = () => {
    return submitted && (!emailValid || !zipValid || !consent);
  }

  return (
    <div ref={titleRef} className={classes.root}>
      <div className={classes.titleSection}>
        <Header hideNavBar={true} />
        <Grid className={classes.titleGrid} container justify="center" alignItems="center" alignContent="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className={classes.title}>The simplest way to get a steady supply of cannabis.</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.titleImg} src={boxImg} alt="Image of a box with a sticky dojo logo"/>
          </Grid>
          <Button className={classes.signupButton} onClick={scrollToSignup} color="primary" variant="contained" size="large">Get Early Access</Button>
        </Grid>
        <svg className={classes.wave} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,128L48,154.7C96,181,192,235,288,224C384,213,480,139,576,128C672,117,768,171,864,170.7C960,171,1056,117,1152,117.3C1248,117,1344,171,1392,197.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      </div>
      
      <div className={classes.howItWorksSection}>
        <Grid ref={howItWorksRef} className={classes.howItWorksGrid} container justify="center">
          <Grid item xs={12} md={4}>
            <img src={howItWorksImg1} className={classes.howItWorksStepImg} alt="e-commerce website icon"/>
            <Typography variant="h4" className={classes.howItWorksStepTitle}>Pick your products.</Typography>
            <Typography variant="body1" className={classes.howItWorksQuote}>"2 eighths of top-shelf sativa"</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <img src={howItWorksImg2} className={classes.howItWorksStepImg} alt="calendar icon"/>
            <Typography variant="h4" className={classes.howItWorksStepTitle}>Pick your interval.</Typography>
            <Typography variant="body1" className={classes.howItWorksQuote}>"Every 2 weeks"</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <img src={howItWorksImg3} className={classes.howItWorksStepImg} alt="tada icon"/>
            <Typography variant="h4" className={classes.howItWorksStepTitle}>Enjoy your products!</Typography>
            <Typography variant="body1" className={classes.howItWorksQuote}><i>"That was easy"</i></Typography>
          </Grid>
        </Grid>
      </div>

      <div ref={faqRef} className={classes.faqSection}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,96L48,85.3C96,75,192,53,288,64C384,75,480,117,576,117.3C672,117,768,75,864,69.3C960,64,1056,96,1152,96C1248,96,1344,64,1392,48L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
        <Typography variant="h3" className={classes.faqTitle}>FAQs</Typography>
        <div className={classes.faqAccordionWrapper}>
          <Accordion>
            <AccordionSummary aria-controls="faq-1-content" id="faq-1-header" expandIcon={<ExpandMoreIcon className={classes.faqQuestionExpandIcon} />}>
              <Typography className={classes.faqQuestion}>How does it work?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqAnswer}>
                You tell us the type, grade, and amount of product you want, and how often you want it.  
                We'll keep refilling that order so you can stay stocked (without wasting time and effort scrolling through cannabis products everytime you re-up).
                We'll also send you a reminder about your scheduled delivery the day before it goes out so there are no surprises.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary aria-controls="faq-2-content" id="faq-2-header" expandIcon={<ExpandMoreIcon className={classes.faqQuestionExpandIcon} />}>
              <Typography className={classes.faqQuestion}>Can I modify my order?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqAnswer}>
                Yes. You can modify your order to change the products you receive. Modifications can be made to apply only to your next order or to all future orders.
                You can also modify the delivery date of your order or skip an order entirely.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary aria-controls="faq-3-content" id="faq-3-header" expandIcon={<ExpandMoreIcon className={classes.faqQuestionExpandIcon} />}>
              <Typography className={classes.faqQuestion}>How much does it cost?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqAnswer}>
                The price of your order is based on the type, grade, and amount of product you select when you create or modify your recurring order. You only pay for orders that you receive.
                You will not be charged for skipped or cancelled orders and there are no subscription fees or cancellation fees.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,64L48,85.3C96,107,192,149,288,144C384,139,480,85,576,74.7C672,64,768,96,864,128C960,160,1056,192,1152,186.7C1248,181,1344,139,1392,117.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      </div>

      {signedUp ?
        <div className={classes.signupComplete}>
          <Checkmark size={128} />
          <Typography variant="h6" className={classes.signupCompleteText}>Thanks!</Typography>
          <Typography variant="h6" className={classes.signupShareText}>Don't forget to share this. By doing so, you support our mission to simplify cannabis purchasing and bring modern-day conveniences to the cannabis industry.</Typography>
        </div> 
        :
        <div className={classes.signupSection}>
          <Typography variant="h6" className={classes.signupText}>Ready for Sticky Dojo to launch in your area?  Let us know by signing up for early access.</Typography>
          <div className={classes.signupForm}>
            <TextField 
                inputRef={signupEmailRef} 
                className={classes.signupEmail} 
                variant="outlined" 
                type="email" 
                label="Email"
                onChange={handleEmailInput}
                error={submitted && !emailValid} />
            <TextField 
                className={classes.signupZip} 
                variant="outlined" 
                type="number"
                label="Zip"
                name="zip"
                onChange={handleZipInput}
                error={submitted && !zipValid} />
            <Button 
                className={classes.signupButton} 
                color="primary" 
                variant="contained"
                size="large"
                onClick={onSignupClick} 
                disabled={signupButtonDisabled()}>Get Early Access</Button>
          </div>
          <div className={classes.signupConsent}>
            <Checkbox 
                className={submitted && !consent ? classes.signupConsentCheckboxRequired : ''}
                color="primary" 
                onChange={handleConsentCheckbox} />
            <span className={classes.signupConsentText}>I certify that I am at least 21 years of age and I agree to the <a href="/terms.html" target="_blank">Terms of Use</a> and <a href="/privacy.html" target="_blank">Privacy Policy</a>.</span>
          </div>
        </div>
      }

      <div className={classes.footer}>
        <div className={classes.shareButtons}>
          <div className={classes.shareFbButtonWrapper}>
            <Like share={true} layout="button" size="large" href="https://stickydojo.com"/>
          </div>
          <div className={classes.shareTwitterWrapper}>
            <a className="twitter-share-button" data-size="large" href="https://twitter.com/intent/tweet?text=I can't wait for this service to go live!&url=https://stickydojo.com&via=stickydojo&hashtags=cannabiscommunity,weedlife">Tweet</a>
          </div>
          <div className={classes.shareSmsButtonWrapper}>
            <a href="sms:?&amp;body=I can't wait for this service to launch! Check it out. https://stickydojo.com">
              <Button className={classes.shareSmsButton} size="small" variant="contained" disableElevation={true} startIcon={<SmsIcon fontSize="small" htmlColor="white"/>}>Text</Button>
            </a>
          </div>
        </div>
        <div className={classes.footerLinks}>
          <a href="https://www.facebook.com/Sticky-Dojo-102539882493642" target="_blank" className={classes.footerIcon}><FacebookIcon htmlColor="#404040" fontSize="inherit" /></a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="https://twitter.com/stickydojo" target="_blank" className={classes.footerIcon}><TwitterIcon htmlColor="#404040" fontSize="inherit" /></a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="mailto: info@stickydojo.com" target="_blank" className={classes.footerIcon}><AlternateEmailIcon fontSize="inherit" /></a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="/terms.html" target="_blank" className={classes.footerLink}>Terms of Use</a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="/privacy.html" target="_blank" className={classes.footerLink}>Privacy Policy</a>
        </div>
        <p>&copy; 2022 STICKY <span className={classes.footerCopyrightDojo}>DOJO</span> LLC.  All rights reserved.</p>
      </div>
    </div>
  );
}

export default Home;